<template>
    <div>

        <video-model ref="videoModel"></video-model>

        <header>
            <h4>云上课堂 > 课程详情</h4>
        </header>

        <main>
            <article
                v-loading="loading" 
                element-loading-background="rgba(0, 0, 0, 0)"
                element-loading-text="拼命加载中"
            >   
                <div class="banner">
                    <el-image :src="info.ccCoverPath" fit="cover" style="width:100%;height:100%;">
                        <div slot="error" class="image-slot">
                        </div>
                    </el-image>
                </div>
        
                <div class="details">
                    <h3>{{info.ccName}}</h3>
                    <dl>
                        <dd>
                            <div v-if="info.ccEnterStart">报名时间：{{info.ccEnterStart | formatDateTime('yyyy-MM-dd HH:mm')}} 至 {{info.ccEnterEnd  | formatDateTime('yyyy-MM-dd HH:mm')}}</div>
                            <div v-else>报名时间：随到随学</div>
                            <div>报名人数：{{info.studentNum}}</div>
                        </dd>
                        <dt>{{info.ccChargeNum > 0 ? '￥'+Number(info.ccChargeNum).toFixed(2)+'元' : '免费' }}</dt>
                    </dl>
                </div>
                <div class="info">{{info.ccIntroduction}}</div>
            </article>


            <aside 
                style="margin: 0 0.28rem;"
                v-loading="liveLoading" 
                element-loading-background="rgba(0, 0, 0, 0)"
                element-loading-text="拼命加载中"
            >
                <div class="title">
                    <h4>直播章节</h4>
                    <i class="el-icon-refresh-left" @click="getLiveList"></i>
                </div>
                <ul v-if="liveList.length > 0">
                    <li v-for="(item,index) of liveList" :key="index" @click="onLiveItem(item)">
                        <div class="content">
                            <div class="name">{{index+1}}、{{item.clName}}</div>
                            <div class="label">
                                <span>主讲教师：{{item.clTeacherName}}</span>
                                <span>{{item.clStartTime  | formatDateTime('yyyy-MM-dd HH:mm')}} 至 {{item.clEndTime  | formatDateTime('yyyy-MM-dd HH:mm')}}</span>
                            </div>
                        </div>
                        <div class="more">查看</div>
                    </li>
                </ul>
                <div class="noData" v-else></div>
            </aside>


            <aside
                v-loading="videoLoading" 
                element-loading-background="rgba(0, 0, 0, 0)"
                element-loading-text="拼命加载中"
            >
                <div class="title">
                    <h4>视频章节</h4>
                    <i class="el-icon-refresh-left" @click="getVideoList"></i>
                </div>
                <ul v-if="videoList.length > 0">
                    <li v-for="(item,index) of videoList" :key="index" @click="onVideoItem(item)">
                        <div class="content">
                            <div class="name">{{index+1}}、{{item.crName}}</div>
                            <div class="label">
                                <span>视频时长：{{item.crVideoTime}}</span>
                            </div>
                        </div>
                        <div class="more">查看</div>
                    </li>
                </ul>
                <div class="noData" v-else></div>
            </aside>
        </main>

    </div>
</template>


<script>
import format from '@/util/timeFormat.js'
import {getCourseDetail, getLiveList, getVideoList, getLiveUrl} from '@/api/classRoomApi'
export default {
    name:'info',
    components:{
        videoModel: () => import('./video.vue')
    },
    data(){
        return {
            id:this.$route.query.id,
            info:'',
            loading:false,
            liveList:[],
            liveLoading:false,
            videoList:[],
            videoUrl:'',
            videoShow:false,
            videoLoading:false
        }
    },
    
    methods:{

        async onLiveItem(item){
            
            let res = await getLiveUrl({
                liveId: item.clId
            })
            if(res.status == 200){
                 window.open(res.data, "_blank")
            } else {
                this.$message.error(res.msg)
            }
           
            
            //  var url =
            // homeServiceCf.liveBaseUrl +
            // 'token=' +
            // Common.getLocalData(loginConfig.token) +
            // '&roleCode=' +
            // Common.getLocalData(loginConfig.roleCode) +
            // '&liveid=' +
            // liveid +
            // '&roomid=' +
            // roomid
            // window.open(url, 'live')


            // var url = `https://lyg.goluckin.com/index.php/index/live?token=${token}&roleCode=${roleCode}&liveid=${liveid}&roomid=${roomid}`
            // window.open(url, "_blank")
        },

        onVideoItem(e){
            this.$refs.videoModel.videoShow = true
            this.$refs.videoModel.videoUrl = e.crVideoUrl
        },

        //基本信息
        async getInfo(){
            this.loading = true
            
            const res = await getCourseDetail({
                ccId: this.id
            })

            if (res.status !== 200){
                this.loading = false
                return this.$message.error(res.msg)
            } 
            
         

            this.info = res.data
            this.loading = false
        },

        //直播章节
        async getLiveList(){
            this.liveLoading = true
            const res = await getLiveList({
                courseId: this.id
            })

            if (res.status > 201 ){
                this.liveLoading= false
                // this.$message.error(res.msg)
                return
            } 

            // res.data = [
            //     {
            //         ccId: "1bb83a5955dfb62967065e9da5826260",
            //         clEndTime: "2020-03-30 12:26:09",
            //         clId: "370384bf8882455ab535e2c9f6f2077f",
            //         clLiveObj: 0,
            //         clName: "直播03300001",
            //         clOrder: 0,
            //         clSchoolId: "ea432352dc154ac7a6d3054a4b39f2bc",
            //         clSchoolName: "福州市国货路小学福州市国货路小学福州市国货路小学名字很长",
            //         clStartTime: "2020-03-30 11:26:07",
            //         clStatus: 0,
            //         clTeacherId: "00a533c12c3f444d9a5d503ceeff4101"

            //     }
            // ]

            if(res.data) this.liveList = res.data
            else this.liveList = []

            this.liveLoading = false
        },

        //视频章节
        async getVideoList(){
            this.videoLoading = true
            const res = await getVideoList({
                ccId: this.id
            })

            if (res.status > 201 ){
                this.videoLoading= false
                return this.$message.error(res.msg)
            } 

            // res.data = [
            //     {
            //         "crCreateTime": "",
            //         "crDeleteFlag": 0,
            //         "crId": "",
            //         "crIdentCode": "",
            //         "crName": "直播名称直播名称直播名称",
            //         "crSchoolId": "",
            //         "crSchoolName": "",
            //         "crSource": "",
            //         "crStatus": 0,
            //         "crUpdateTime": "",
            //         "crVideoCapacity": "",
            //         "crVideoTime": "1635782399000",
            //         "crVideoUrl": "https://www.w3school.com.cn/example/html5/mov_bbb.mp4"
            //     }
            // ]

            if(res.data){
                // for(let item of res.data){
                //     item.crVideoTime = format.timeStamp(item.crVideoTime)
                // }
                this.videoList = res.data
            } else {
                this.videoList = []
            }

            this.videoLoading = false
        }
    },
  
    mounted(){
        
        this.getInfo()
        this.getLiveList()
        this.getVideoList()
    }
}
</script>


<style lang="scss" scoped>
header{
    height:0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding:0 0.58rem;
    h4{
        color:#fff;
        font-size:0.26rem;
    }
}
main{
    box-sizing: border-box;
    padding:0 0.58rem;
    height:calc(100vh - 1.7rem);
    display: flex;
}
article{
    width:6.82rem;
    height: calc(100vh - 1.5rem);
    overflow: scroll;
    
    .banner{
        background: #f4f8fb;
        width:100%;
        height:3.96rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background:#f4f8fb;
        background-size:1rem;
        img{
            object-fit:cover;
            cursor: pointer;
        }
    }
    .details{
        margin-top:0.2rem;
        h3{
            font-size:0.22rem;
            color:#fff;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            cursor: pointer;
        }
        dl{
            display: flex;
            justify-content: space-between;
            margin-top:0.2rem;
            dd{
                font-size:0.16rem;
                color:#ABB0BF;
            }
            dt{
                font-size:0.26rem;
                color:#FFA800;
                cursor: pointer;
            }
        }
    }
    .info{
        font-size:0.16rem;
        color:#DFE7F7;
        line-height:0.3rem;
        margin-top:0.5rem;
        // height:calc(100vh - 7.28rem);
        // overflow: hidden;
        // overflow-y: auto;
        text-align: justify;
        text-indent: 2em;
        cursor: pointer;
        white-space: pre-wrap;
    }
}
aside{
    flex:1;
    background-color: rgba(1, 19, 67, 0.6);
    .title{
        height:0.55rem;
        box-sizing: border-box;
        padding:0 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4{
            font-size:0.18rem;
            color:#fff;
            font-weight:700;
        }
        i{
            color:#fff;
            opacity:0.6;
            font-size:0.24rem;
            cursor: pointer;
        }
    }
    .noData{
        height:calc(100vh - 2.2rem);
        background:url(../../assets/img/noData.png) center no-repeat;
        background-size:50%;
    }
    ul{
        box-sizing: border-box;
        padding:0 0.2rem;
        height:calc(100vh - 2.2rem);
        overflow: hidden;
        overflow-y: auto;
        li{
            box-sizing: border-box;
            padding:0.2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .content{
                flex:1;
                .name{
                    font-size:0.16rem;
                    color:#00A2FF;
                }
                .label{
                    font-size:0.14rem;
                    color:#B1CEF4;
                    margin-top:0.14rem;
                    span:first-child{
                        padding-right:0.2rem;
                    }
                }
            }
            .more{
                font-size:0.14rem;
                color:#00F9FF;
            }
        }
        li:nth-child(odd){
            background-color:#1D336E;
        }
        li:hover{
            background-color: #00A2FF;
            .content{
               .name{
                    color:#fff;
                } 
            }
            .more{
                color:#fff;
            }
        }
    }
}
</style>

<style>
.image-slot{
    width:3.38rem;
    height:1.96rem;
    background: url(../../assets/img/icon.png) center no-repeat;
    background-size:1rem;
}
</style>